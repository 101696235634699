<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="workOrdersFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            @click:row="openProcessWorkOrder"
            fixed-header
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">WORK ORDERS</h1>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.projectManager`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.projectManager.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.weight`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ (item.weight || 0).toFixed(2) }} kg</p>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--PROCESS WORK ORDER-->
        <v-dialog
            v-model="processWorkOrder"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessWorkOrder
                v-if="processWorkOrder"
                :order="selectedOrder"
                @close="closeProcessWorkOrder"
            />
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { firestore } from '@/services/firebase'
import API from '@/services/api'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import SocketioService from '@/services/websocket/socket.service.js'

export default {
    name: 'ReadyForPackingWO',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
        project: {
            type: Object,
            deafult: () => ({}),
        },
    },
    components: {
        ProcessWorkOrder: () =>
            import('@/components/WorkOrders/ProcessWorkOrder.vue'),
    },
    data: () => ({
        selectedOrder: undefined,
        processWorkOrder: false,
        workOrder: false,
        projectToFilter: undefined,
        loading: false,
        workOrders: [],
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
                width: 120,
            },
            {
                text: 'PROJECT MANAGER',
                value: 'projectManager',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
                width: 240,
            },
            {
                text: 'TOTAL WEIGHT',
                value: 'weight',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY READY FOR PACKING',
                value: 'qtyReadyForPacking',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RELEASE DATE',
                value: 'releaseDate',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
        ],
        company: JSON.parse(localStorage.getItem('company')),
        listener: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        enableOptions: true,
        resourceId: undefined,
        processToShow: undefined,
        settingProcesses: undefined,
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
        user: {},
    }),
    computed: {
        ...mapState(['notificationResource']),
        workOrdersFiltered() {
            this.openResource()
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (conditions.length > 0) {
                return this.workOrders.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }

            this.sort()
            const pendingWOs = this.workOrders.filter(
                wo => wo.qtyReadyForPacking > 0
            )
            return pendingWOs
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            SocketioService.joinRoom(`${this.companyId}-WO`)
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')
            const {
                data: { users },
            } = await API.getLiteUsers()
            const projects = await API.getLiteProjects({ all: true })
            let query = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('workOrders')
                .where('status', '==', 'approved')
            if (!user.permissions.includes('readAllWO')) {
                query = query.where('createdBy', '==', user.id)
            }
            this.listener = await query.onSnapshot(async docSnapshot => {
                docSnapshot.docChanges().forEach(async change => {
                    const workOrder = Object.assign(change.doc.data(), {
                        id: change.doc.id,
                    })
                    const userIndex = users.findIndex(
                        u => u.id == workOrder.projectManager
                    )
                    if (userIndex >= 0) {
                        workOrder.projectManager = users[userIndex]
                    } else {
                        workOrder.projectManager = {
                            id: workOrder.projectManager,
                        }
                        workOrder.projectManager.name = ''
                    }
                    if (change.type === 'added') {
                        const projectIndex = projects.findIndex(
                            p => p.id == workOrder.projectId
                        )
                        if (projectIndex >= 0) {
                            workOrder.project = projects[projectIndex]
                        } else {
                            workOrder.project = await API.getProject(
                                workOrder.projectId
                            )
                            projects.push(workOrder.project)
                        }
                        this.workOrders.splice(0, 0, workOrder)
                    }
                    if (change.type === 'modified') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            const projectIndex = projects.findIndex(
                                p => p.id == workOrder.projectId
                            )
                            if (projectIndex >= 0) {
                                workOrder.project = projects[projectIndex]
                            } else {
                                workOrder.project = await API.getProject(
                                    workOrder.projectId
                                )
                                projects.push(workOrder.project)
                            }
                            this.workOrders.splice(index, 1, workOrder)
                        }
                    }
                    if (change.type === 'removed') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            this.workOrders.splice(index, 1)
                        }
                    }
                    this.setProcessesInfo(this.formatItems(workOrder))
                })
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        openProcessWorkOrder(item) {
            this.selectedOrder = _.cloneDeep(item)
            this.processWorkOrder = true
        },
        closeProcessWorkOrder() {
            this.processWorkOrder = false
        },
        filterOrder(order) {
            return order.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterProject(order) {
            return (
                order.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                order.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        sort() {
            this.workOrders.sort(
                (a, b) =>
                    (b.qtyReadyForPacking || 0) - (a.qtyReadyForPacking || 0)
            )
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.workOrders.find(
                    w => w.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openProcessWorkOrder(resource)
                }
            }
        },
        setProcessesInfo(wo) {
            //let totalProduced = 0
            let totalPacked = 0
            let totalReceived = 0

            let releaseSeconds = Date.now() / 1000
            wo.items.forEach(item => {
                if (item.processes) {
                    if (item.deliveries) {
                        let sentToPacking = item.deliveries.filter(
                            register =>
                                register.nextProcess.id ==
                                    this.packingProcess.id ||
                                register.nextProcess.id ==
                                    this.readyForPackingProcess.id
                        )

                        sentToPacking.forEach(d => {
                            if (
                                d.deliveredOn &&
                                d.deliveredOn.seconds < releaseSeconds
                            ) {
                                releaseSeconds = d.deliveredOn.seconds
                            }
                        })

                        totalReceived += sentToPacking.reduce(
                            (total, register) => total + register.qty,
                            0
                        )
                    }
                }
                totalPacked += Number(item.packedQty) || 0
            })
            wo.qtyReadyForPacking = totalReceived - totalPacked
            wo.releaseDate = this.formatDate(releaseSeconds)
            if (this.selectedOrder && this.selectedOrder.id == wo.id) {
                this.selectedOrder = _.cloneDeep(wo)
            }
        },
        formatItems(workOrder) {
            if (
                !(
                    (workOrder.status == 'closed' ||
                        workOrder.status == 'cancelled') &&
                    !workOrder.partNumbers
                )
            ) {
                delete workOrder.items
                workOrder.items = []
            }
            if (workOrder.partNumbers) {
                Object.keys(workOrder.partNumbers).forEach(partNumberId => {
                    let item = {
                        partNumberId,
                    }
                    Object.keys(workOrder.partNumbers[partNumberId]).forEach(
                        key => {
                            if (key == 'assemblyItems') {
                                let assemblyItems = []
                                Object.keys(
                                    workOrder.partNumbers[partNumberId][key]
                                ).forEach(aiKey => {
                                    assemblyItems.push({
                                        partNumberId: aiKey,
                                        ...workOrder.partNumbers[partNumberId][
                                            key
                                        ][aiKey],
                                    })
                                })
                                item[key] = assemblyItems
                            } else {
                                item[key] =
                                    workOrder.partNumbers[partNumberId][key]
                            }
                        }
                    )
                    workOrder.items.push(item)
                })
            }
            return workOrder
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important;
}
.v-data-table__wrapper {
    max-height: 72vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px;
}
.v-data-table__wrapper thead tr {
    position: sticky;
    top: 0;
    z-index: 10;
}
thead {
    position: sticky;
    z-index: 1;
}
</style>
